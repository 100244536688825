// import { useTheme } from 'next-themes'
// import Image from 'next/image'

interface LogoProps {
  className?: string
  size?: number
}

export const Logo = (props: LogoProps) => {
  // const { resolvedTheme } = useTheme()
  // const url = resolvedTheme === 'light' ? `/logos/logo-outline-dark@2x.png` : `/logos/logo-outline@2x.png`
  return (
    <div>Logo</div>
    // <Image className={props.className} src={url} width={props.size || 24} height={props.size || 24} alt="Fadeaway" />
  )
}

export function LogoOutline(props: { color: string; width?: number }) {
  const width = props.width || 84
  const height = (width / 84) * 76
  return (
    <svg width={`${width}`} height={`${height}`} viewBox="0 0 84 76" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M2 2V10.9365L19.4593 19.828M2 2H64.5783L82 10.9365M2 2L19.4593 10.9365M82 10.9365H19.4593M82 10.9365V19.828H19.4593M19.4593 10.9365V19.828M19.4593 19.828H2M2 19.828V28.795L19.4585 37.6996M2 19.828L19.4585 28.7958M19.4585 37.6996V28.7958M19.4585 37.6996H2.00081M19.4585 37.6996H65.3345M19.4585 28.7958H48.0041L65.3345 37.6996M65.3345 37.6996V46.6049H19.4585M19.4585 46.6049L2.00081 37.6996M19.4585 46.6049V55.5264M2.00081 37.6996L2.00084 46.6171L19.4585 55.5264M19.4585 55.5264H28.7225L46.134 64.4626M19.4585 55.5264H2.00081M46.134 64.4626H19.4586M46.134 64.4626V73.3842H19.4586M19.4586 64.4626L2.00081 55.5264M19.4586 64.4626V73.3842M2.00081 55.5264L2.00084 64.4634L19.4586 73.3842"
        stroke={props.color}
        strokeWidth="3.37802"
        strokeLinejoin="round"
      />
    </svg>
  )
}

export function LogoBlock(props: { color: string; width?: number }) {
  const width = props.width || 82
  const height = (width / 82) * 72
  return (
    <svg width={`${width}`} height={`${height}`} viewBox="0 0 82 72" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M80.2863 15.3379H18.9536V10.0494H80.2863V15.3379ZM74.0053 6.04938H17.4357L7.01175 0.713867H63.6037L74.0053 6.04938ZM0.714844 6.82351V1.98433L14.9536 9.27245V14.0749L0.714844 6.82351ZM56.6074 34.0074H18.9528V28.7061H46.289L56.6074 34.0074ZM0.714844 20.6137L14.9528 27.9272V32.7422L0.714844 25.4802V20.6137ZM16.9528 38.0074H62.8767V43.3103H17.4334L7.03777 38.0074H16.9528ZM0.715701 39.2728L14.9528 46.5353V51.3642L0.715716 44.0983L0.715701 39.2728ZM17.4349 61.9654L7.0122 56.6302H16.9528H26.147L36.5422 61.9654H17.4349ZM18.9528 65.9654H42.8191V71.2853H18.9528V65.9654ZM0.715716 62.7422L14.9528 70.0173V65.1884L0.715701 57.9008L0.715716 62.7422Z"
        fill={props.color}
      />
    </svg>
  )
}
