import { useEffect, useState } from 'react'

export function useUserAgent() {
  const [arch, setArch] = useState<'arm' | 'x86'>()
  const [platform, setPlatform] = useState<'macOS' | 'Windows'>()

  useEffect(() => {
    // userAgentData is supported in Chrome and Edge, but not in Safari.
    //
    // See https://developer.mozilla.org/en-US/docs/Web/API/NavigatorUAData/getHighEntropyValues
    if ('userAgentData' in navigator) {
      const userAgentData = navigator.userAgentData as any
      userAgentData.getHighEntropyValues(['architecture']).then((values: any) => {
        setArch(values.architecture)
        setPlatform(values.platform)
      })
    } else {
      // We can only detect the platform, but not the architecture.
      if (navigator.userAgent.includes('Mac')) {
        setPlatform('macOS')
      } else if (navigator.userAgent.includes('Win')) {
        setPlatform('Windows')
      }
    }
  }, [])

  return {
    arch,
    platform,
  }
}

export function toNodePlatform(platform: 'macOS' | 'Windows'): NodeJS.Platform | undefined {
  if (platform === 'macOS') {
    return 'darwin'
  } else if (platform === 'Windows') {
    return 'win32'
  }
}

export function toNodeArch(arch: 'arm' | 'x86'): NodeJS.Architecture | undefined {
  if (arch === 'arm') {
    return 'arm64'
  } else if (arch === 'x86') {
    return 'x64'
  }
}
