import { useRouter } from 'next/router'
import { useEffect } from 'react'
import { LogoOutline } from '../components/Logo'
import { toNodeArch, toNodePlatform, useUserAgent } from '../components/hooks/useUserAgent'
import { Input } from '../components/input'

export default function IndexPage() {
  const router = useRouter()

  const userAgent = useUserAgent()
  const platform = userAgent.platform ? toNodePlatform(userAgent.platform) : undefined
  const arch = userAgent.arch ? toNodeArch(userAgent.arch) : undefined

  const isVerify = router.asPath.includes('/#verify')

  useEffect(() => {
    const interval = setInterval(() => {
      console.log('fadeaway is', (window as any).fadeaway)
    }, 10000)
    return () => {
      clearInterval(interval)
    }
  }, [])

  if (isVerify) {
    return (
      <div className="flex h-[100vh] flex-col items-center justify-center space-y-12 px-12">
        <Input
          key="password"
          placeholder="Password"
          autoFocus
          onKeyDown={e => {
            if (e.key === 'Enter') {
              alert('submit')
            }
          }}
        />
        <div className="opacity-30">
          <LogoOutline color="black" width={50} />
        </div>
      </div>
    )
  }

  return (
    <div className="flex h-[100vh] flex-col items-center justify-center space-y-12 px-12">
      <Input
        key="email"
        placeholder="Email"
        type="email"
        autoFocus
        onKeyDown={e => {
          if (e.key === 'Enter') {
            fetch('/api/ping')
            router.push('/#verify').catch(e => console.error(e))
          }
        }}
      />
      <div className="opacity-30">
        <LogoOutline color="black" width={50} />
      </div>
      <div className="flex flex-col gap-2">
        <div className="flex flex-col">
          {platform && arch && (
            <a href={`/api/download?platform=${platform}&arch=${arch}`} target="_blank" rel="noopener noreferrer">
              Download Fadeaway
            </a>
          )}

          <a href="/api/download?platform=win32" target="_blank" rel="noopener noreferrer">
            Download Fadeaway for Windows
          </a>
          <a href="/api/download?platform=darwin&arch=x86" target="_blank" rel="noopener noreferrer">
            Download Fadeaway for macOS intel
          </a>
          <a href="/api/download?platform=darwin&arch=arm64" target="_blank" rel="noopener noreferrer">
            Download Fadeaway for macOS M1
          </a>
        </div>

        <div className="flex flex-col">
          {platform && arch && (
            <a
              href={`/api/download?platform=${platform}&arch=${arch}&beta=true`}
              target="_blank"
              rel="noopener noreferrer"
            >
              Download Fadeaway (Beta)
            </a>
          )}
          <a href="/api/download?platform=win32&beta=true" target="_blank" rel="noopener noreferrer">
            Download Fadeaway for Windows (Beta)
          </a>
          <a href="/api/download?platform=darwin&arch=x86&beta=true" target="_blank" rel="noopener noreferrer">
            Download Fadeaway for macOS intel (Beta)
          </a>
          <a href="/api/download?platform=darwin&arch=arm64&beta=true" target="_blank" rel="noopener noreferrer">
            Download Fadeaway for macOS M1 (Beta)
          </a>
        </div>
      </div>
    </div>
  )
}
